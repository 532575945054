


















import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {
  CaseApiFactory,
  CaseDTO,
  CaseStatus,
  Configuration, LocationDto,
} from "@shared_vue/openapi/caseapi";

import TrackMeView from "@/views/viewcomponents/TrackMeView.vue";

@Component({
  components: {TrackMeView },
})
export default class TrackMePage extends Vue {
  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private caseApi = CaseApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
    process.env.VUE_APP_CASE_API_URL
  );
  @Prop(String) readonly caseid: string|undefined;
  private locations: LocationDto[] = [];
  private auth = vxm.auth;
  private showWait: boolean = false;
  private waitABit = false;
  private intervalId:any;
  
  get currentCase() {
    return this.caseStore.openCase;
  }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.fetchLocationData()
    }
  }
  
  private get lastLocation(){
    if (this.locations&&this.locations.length>0) {
        return this.locations[this.locations.length - 1];  
    }
    return {latutide:0, longitude:0};
  }
  
  public async fetchLocationData(){
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
    let result = await this.caseApi.caseGetLocationsForCase(parseInt(this.caseid!));
    
    if (result.status==200){
      this.locations = result.data;
    }
    
  }

  mounted(){
    this.fetchLocationData();
    this.intervalId = window.setInterval(() => {
      this.fetchLocationData();
    }, 10000);
  }

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
