<template>
  <CCard>
    <CCardHeader>
      TrackMe
    </CCardHeader>
    <CRow>
      <CCol col="8">
        <CCardBody>
          <GmapMap
              :center.sync="center"
              :zoom="13"
              style="height: 800px"
          >
            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                            @closeclick="infoWinOpen=false">
              <span>{{infoContent}}</span>
            </GmapInfoWindow>
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="m.draggable"
                :infotext="m.timestamp"
                @click="toggleInfoWindow(m, index)"
            />
          </GmapMap>
        </CCardBody>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import * as CaseStuff from "@shared_vue/openapi/caseapi";

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBLNNIOJ-dM1z23gaqTQYJcYiPUckcvRPw'
    // key: ''
    // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
    // v: 'OPTIONAL VERSION NUMBER',
    // libraries: 'places', //// If you need to use place input
  }
});

export default {
  name: 'TrackMeView',
  components: {},
  props: {
    center: {
      type: Object,
      default: {lat: -29.7857789, lng: 30.7703669}
    },
    locationData: {
      type: Array,
      default: () => []
    }
  },
  data() { //move data to mock API
    return {
      // center: {lat: -29.7857789, lng: 30.7703669},
      infoContent: '',
      infoLink: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  computed:{
    markers: function(){
      return this.locationData?.map((el)=>({
        position: {lat: Number(el.latutide), lng: Number(el.longitude)},
        timestamp:el.created,
        draggable: false,
      }))??[]
    }
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker.timestamp
      
      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.currentMidx = idx
        this.infoWinOpen = true
      }
    }
  },
  
}

</script>

<style scoped>

</style>
